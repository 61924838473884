// src/components/ServiceBookingComponent.js
import React, { useState } from 'react';

const NurseBooking = ({ onSubmitBooking }) => {
  const [bookingDetails, setBookingDetails] = useState({
    serviceType: '',
    patientAge: '',
    careDuration: '',
    startDate: '',
    contactInfo: '',
  });

  const handleChange = (e) => {
    setBookingDetails({ ...bookingDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitBooking(bookingDetails);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Book Nursing Services</h2>
      <p> Here on our health platform, We can connect you with our vast network of nurses and get your care needs met.</p>
      {/* Add form fields for booking details here */}
      <button id="joinUsBtn" className="center-button" type="submit">Submit Booking</button>
    </form>
  );
};

export default NurseBooking;

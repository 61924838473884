// src/components/RegistrationComponent.js
import React from 'react';

const Registration = ({ userType, onSubmit }) => (
  <form onSubmit={(e) => { e.preventDefault(); onSubmit(/* form data */); }}>
    <h4>Register as {userType}</h4>
    {/* Registration form fields */}
    <button type="submit">Submit</button>
  </form>
);

export default Registration;

// src/utils/ServiceCard.js
import React from 'react';

const ServiceCard = ({ title, description, onBookClick }) => {
  return (
    <div className="card" style={{ width: '18rem', margin: '1rem' }}>
      <div className="card-body">
        <h2 className="card-title">{title}</h2>
        <p className="card-text">{description}</p>
        <button id="joinUsBtn" className="center-button" onClick={onBookClick}>Book Now</button>
      </div>
    </div>
  );
};

export default ServiceCard;

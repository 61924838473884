// src/pages/NutritionServices.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles
import ServiceCard from '../utils/ServiceCard';

const nutritionServices = [
  {
    id: 1,
    title: 'Personalized Nutrition Plans',
    description: 'Custom diet plans tailored to your health goals and dietary preferences.',
  },
  {
    id: 2,
    title: 'Nutrition Workshops',
    description: 'Join our group sessions to learn about healthy eating, meal prep, and nutrients.',
  },
  {
    id: 3,
    title: 'Dietary Analysis',
    description: 'Get a detailed analysis of your current eating habits with professional recommendations.',
  },
  {
    id: 4,
    title: 'Weight Management Programs',
    description: 'Personalized programs for weight loss, gain, or maintenance, guided by nutritional experts.',
  },
];

const NutritionServices = () => {
  const handleBookClick = (serviceId) => {
    console.log(`Booking nutrition service with ID: ${serviceId}`);
    // Implement booking functionality here
  };

  return (
    <div>
      <div id="home-banner4" className="banner">
        <div className="container">
          <div className="header-text">
            <h1>Our Nutrition Services</h1>
          </div>
        </div>
      </div>

      <div id="mainContent" className="contact">
       <section>
          <Swiper
            spaceBetween={30} // Space between slides
            slidesPerView={3} // Number of slides to show at once
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            className="mySwiper"
          >
            {nutritionServices.map((service) => (
              <SwiperSlide key={service.id}>
                <ServiceCard
                  title={service.title}
                  description={service.description}
                  onBookClick={() => handleBookClick(service.id)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
        <section>
        </section>
      </div>

      <div id="home-banner5" className="bottom-banner">
        <div className="container">
          <div className="header-text"></div>
        </div>
      </div>

    </div>
  );
};

export default NutritionServices;

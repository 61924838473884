// src/utils/TherapistFilter.js
import React from 'react';

const TherapistFilter = ({ onFilterChange }) => (
  <div>
    <h2>Find a Therapist</h2>
    {/* Implement filter UI here */}
    <button id="joinUsBtn" className="center-button" onClick={() => onFilterChange({/* filter values */})}>Apply Filters</button>
  </div>
);

export default TherapistFilter;

// src/components/VideoChatComponent.js
import React from 'react';

const VideoChat = ({ onStartCall }) => (
  <div>
    <h2>Start a Video Call</h2>
    <button id="joinUsBtn" className="center-button" onClick={() => onStartCall(/* session details */)}>Start Call</button>
  </div>
);

export default VideoChat;

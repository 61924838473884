import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleServicesClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    if (location.pathname === "/") {
      const servicesSection = document.getElementById('services');
      if (servicesSection) {
        servicesSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate("/#services");
    }
  };

  return (
    <header id="mainHeader">
      <div id="logo">
        <Link to="/">
          <img
            src="/logo512.png"
            id="logo-img"
            alt="Logo for LxRose"
          />
        </Link>
      </div>
      <nav id="mainNav">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
          <a href="/#services" onClick={handleServicesClick}>Services</a>
            {showDropdown && (
              <div className="dropdown-content">
                <Link to="/mentalservices">Mental Health Services</Link>
                <Link to="/nutritionservices">Nutrition Services</Link>
                <Link to="/nursingservices">Nursing Services</Link>
              </div>
            )}
          </li>
          <li><Link to="/contact">Contact Us</Link></li>
          <li><Link to="/about">About</Link></li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
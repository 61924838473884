// src/pages/NursingServices.js
import React from 'react';
import NurseBooking from '../utils/NurseBooking';
import NurseRegistration from '../utils/NurseRegistration';

const NursingServices = () => {
  const handleBookingSubmit = (bookingDetails) => {
    console.log('Booking details:', bookingDetails);
    // Here you would handle the booking submission, likely sending it to a backend server
  };

  const handleNurseRegister = (nurseInfo) => {
    console.log('Nurse Info:', nurseInfo);
    // Handle nurse registration similarly, sending data to the backend
  };

  return (
    <div>

      <div id="home-banner4" className="banner">
        <div className="container">
          <div className="header-text">
            <h1>Get Our Nursing Services</h1>
          </div>
        </div>
      </div>

      <section>
      <NurseBooking onSubmitBooking={handleBookingSubmit} />
      </section>
      <section>
      <NurseRegistration onRegister={handleNurseRegister} />
      {/* Additional sections for nurse referral system and employment platform can be added here */}
      </section>

     

      <div id="home-banner5" className="bottom-banner">
        <div className="container">
          <div className="header-text"></div>
        </div>
      </div>
    </div>
  );
};

export default NursingServices;

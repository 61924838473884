// src/components/NurseRegistrationComponent.js
import React, { useState } from 'react';

const NurseRegistration = ({ onRegister }) => {
  const [nurseInfo, setNurseInfo] = useState({
    name: '',
    qualifications: '',
    yearsOfExperience: '',
    availability: '',
  });

  const handleChange = (e) => {
    setNurseInfo({ ...nurseInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onRegister(nurseInfo);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Register as a Nurse</h2>
      <p>Register on our platform and we will find you clients looking for compassionate and caring nurses.</p>
      {/* Add form fields for nurse registration here */}
      <button id="joinUsBtn" className="center-button" type="submit">Register</button>
    </form>
  );
};

export default NurseRegistration;

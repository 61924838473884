import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles
import ServiceCard from '../utils/ServiceCard';
import TherapistFilter from '../utils/TherapistFilter';
import Registration from '../utils/Registration';
import VideoChat from '../utils/VideoChat';

const services = [
  {
    id: 1,
    title: 'Single Online Therapy Session',
    description: 'Personalized therapy focusing on individual needs, providing a private space to discuss and navigate personal challenges.',
  },
  {
    id: 2,
    title: 'Group Therapy Session',
    description: 'Join a supportive group of peers facing similar challenges, facilitated by a professional therapist to guide the discussion.',
  },
  {
    id: 3,
    title: 'Child Therapy Session',
    description: 'Specialized therapy for children, using engaging methods to help them express their feelings and work through issues.',
  },
  {
    id: 4,
    title: 'Family Therapy Session',
    description: 'Therapy sessions for families looking to improve communication, resolve conflicts, and strengthen their bond.',
  },
  // Add more services as needed
];

const MentalServices = () => {
  const [showRegistration, setShowRegistration] = useState(false);
  const [userType, setUserType] = useState('');

  const handleBookClick = (serviceId) => {
    console.log(`Booking service with ID: ${serviceId}`);
    // Here you might set state to show a booking form or navigate to a booking page
  };

  const handleFilterChange = (filters) => {
    console.log('Applying filters:', filters);
    // Implement filtering logic or API call to fetch filtered therapists
  };

  const handleUserRegistration = (formData) => {
    console.log('Registering user with data:', formData);
    // Implement registration logic or API call
  };

  const handleVideoCall = (sessionDetails) => {
    console.log('Starting video call with details:', sessionDetails);
    // Implement video call logic or integrate with a video call API
  };

  return (
    <div>
      <div id="home-banner4" className="banner">
        <div className="container">
          <div className="header-text">
            <h1>Mental Health Services</h1>
          </div>
        </div>
      </div>

      <div id="mainContent" className="contact">
        <section>
            <TherapistFilter onFilterChange={handleFilterChange} />
        </section>

        <section>

            {/* Swiper implementation replaces the previous row div */}
            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log('slide change')}
              className="mySwiper"
            >
              {services.map((service) => (
                <SwiperSlide key={service.id}>
                  <ServiceCard
                    title={service.title}
                    description={service.description}
                    onBookClick={() => handleBookClick(service.id)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
        </section>

        <section id='register-btns'>
            <button  onClick={() => { setShowRegistration(true); setUserType('patient'); }}>Register as Patient</button>
            <button  onClick={() => { setShowRegistration(true); setUserType('therapist'); }}>Register as Therapist</button>

            {showRegistration && <Registration userType={userType} onSubmit={handleUserRegistration} />}
        </section>
        <section>
        <VideoChat onStartCall={handleVideoCall} />
        </section>

      </div>

      <div id="home-banner5" className="bottom-banner">
        <div className="container">
          <div className="header-text"></div>
        </div>
      </div>
    </div>
  );
};

export default MentalServices;
